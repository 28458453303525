import React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  LPickupList,
  CCard,
  CBtnList,
  CAnchorNav,
  LStayPlanContact,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'STAY PLAN',
            sub: 'お祝い・記念日 宿泊プラン',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/anniversary/stay_plan/kv.png',
              },
              imgSp: {
                src: '/assets/images/anniversary/stay_plan/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'お祝い・記念日',
                path: '/anniversary/',
              },
            ],
            current: {
              label: 'お祝い・記念日 宿泊プラン',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mtMedium u_mbExLarge">
        <div className="u_pt20 u_mb60">
          <LWrap>
            <CAnchorNav
              exClass="u_sp"
              data={[
                {
                  link: {
                    href: '#stay_plan',
                  },
                  title: 'お祝い・記念日 宿泊プラン',
                },
                {
                  link: {
                    href: '#option',
                  },
                  title: 'オプション',
                },
              ]}
            />
          </LWrap>
        </div>
        <LWrap>
          <p className="c_sectLead">
            誕生日、結婚記念日など大切な人と過ごす記念日は特別なもの。
            <br />
            ケーキ付きやシャンパン付きなど、非日常のサプライズ付きプランで、最高の1日を叶えて。
          </p>

          <div className="u_pt20 u_mb60">
            <LWrap>
              <CAnchorNav
                exClass="u_pc"
                data={[
                  {
                    link: {
                      href: '#stay_plan',
                    },
                    title: 'お祝い・記念日 宿泊プラン',
                  },
                  {
                    link: {
                      href: '#option',
                    },
                    title: 'オプション',
                  },
                ]}
              />
            </LWrap>
          </div>

          <section id="stay_plan">
            <CSectTitle
              title={{
                ja: 'お祝い・記念日 宿泊プラン',
                en: 'STAY PLAN',
              }}
            />
            <LPickupList
              classification={['宿泊アニバーサリー']}
              btnlabel="プラン詳細"
              contact=""
            />
          </section>

          <section className="l_sect" id="option">
            <CSectTitle title={{ ja: <>オプション</>, en: <>OPTION</> }} />
            <p className="c_sectLead">
              お誕生日やプロポーズのサプライズに。お花の手配や、ご宿泊のお部屋のデコレーションなどを承ります。
              <br />
              お花の色や料金等はお気軽にご相談ください。
            </p>
            <CCard
              col={2}
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/stay_plan/img_optional.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/anniversary/stay_plan/img_optional__sp.png',
                    alt: '',
                  },
                  title: <>12本のバラのブーケ</>,
                  content: (
                    <>
                      12本のバラは「感謝　誠実　幸福　信頼　希望　愛情　情熱　真実　尊敬　栄光　努力　永遠」の象徴。「これらを全てあなたに誓います。」という気持ちを込めた花束です。
                    </>
                  ),
                  definition: [
                    {
                      title: '料金',
                      text: <>13,200円〜</>,
                    },
                  ],
                  footer: (
                    <>
                      <ul className="c_noteList">
                        <li>要予約（4日前まで）</li>
                        <li>
                          花器はレンタル品のためお持ち帰りいただけません。
                        </li>
                        <li>料金には消費税が含まれます。</li>
                      </ul>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/stay_plan/img_optional02.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/anniversary/stay_plan/img_optional02__sp.png',
                    alt: '',
                  },
                  title: <>バラの花束</>,
                  content: <>バラの花束をお部屋にご用意いたします。</>,
                  definition: [
                    {
                      title: '料金',
                      text: (
                        <>
                          13,200円～
                          <br />
                          108本（写真）　118,800円〜
                        </>
                      ),
                    },
                  ],
                  footer: (
                    <>
                      <ul className="c_noteList">
                        <li>要予約（4日前まで）</li>
                        <li>料金には消費税が含まれます。</li>
                      </ul>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/stay_plan/img_optional03.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/anniversary/stay_plan/img_optional03__sp.png',
                    alt: '',
                  },
                  title: <>バルーンデコレーション</>,
                  content: (
                    <>
                      誕生日やプロポーズのサプライズ演出に人気のオプションです。フィルムバルーン、その他バルーンの色の変更も承ります。
                    </>
                  ),
                  definition: [
                    {
                      title: '料金',
                      text: <>44,000円</>,
                    },
                  ],
                  footer: (
                    <>
                      <ul className="c_noteList">
                        <li>要予約（1週間前まで）</li>
                        <li>料金には消費税が含まれます。</li>
                        <li>
                          お部屋の装飾は、チェックイン前とさせていただきます。
                        </li>
                        <li>
                          ヘリウムガスの入手が困難な状況のため、浮かないバルーンのご用意となります。
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/stay_plan/img_optional04.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/anniversary/stay_plan/img_optional04__sp.png',
                    alt: '',
                  },
                  title: <>文字バルーン付き バルーンデコレーション</>,
                  content: (
                    <>
                      人気のバルーンデコレーションにメッセージを添えて。文字内容やバルーンの色の変更も承ります。
                    </>
                  ),
                  definition: [
                    {
                      title: '料金',
                      text: <>33,000円</>,
                    },
                  ],
                  footer: (
                    <>
                      <ul className="c_noteList">
                        <li>要予約（1週間前まで）</li>
                        <li>料金には消費税が含まれます。</li>
                        <li>
                          お部屋の装飾は、チェックイン前とさせていただきます。
                        </li>
                        <li>
                          ヘリウムガスの入手が困難な状況のため、浮かないバルーンのご用意となります。
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/stay_plan/img_optional05.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/anniversary/stay_plan/img_optional05__sp.png',
                    alt: '',
                  },
                  title: <>ベッドフラワー＆バルーンデコレーション</>,
                  content: (
                    <>
                      ベッドフラワーとバルーンデコレーションがセットになったオプションです。文字内容やバルーンの色の変更も承ります。
                    </>
                  ),
                  definition: [
                    {
                      title: '料金',
                      text: <>88,000円</>,
                    },
                  ],
                  footer: (
                    <>
                      <ul className="c_noteList">
                        <li>要予約（1週間前まで）</li>
                        <li>料金には消費税が含まれます。</li>
                        <li>
                          お部屋の装飾は、チェックイン前とさせていただきます。
                        </li>
                        <li>
                          ヘリウムガスの入手が困難な状況のため、浮かないバルーンのご用意となります。
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/stay_plan/img_optional06.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/anniversary/stay_plan/img_optional06__sp.png',
                    alt: '',
                  },
                  title: <>フラワーロード</>,
                  content: (
                    <>
                      赤い花びらロードの先にプレゼントを置いて、ロマンティックな演出を。
                    </>
                  ),
                  definition: [
                    {
                      title: '料金',
                      text: (
                        <>
                          フラワーのみ 22,000円
                          <br />
                          キャンドル付 33,000円
                        </>
                      ),
                    },
                  ],
                  footer: (
                    <>
                      <ul className="c_noteList">
                        <li>要予約（1週間前まで）</li>
                        <li>料金には消費税が含まれます。</li>
                        <li>
                          お部屋の装飾は、チェックイン前とさせていただきます。
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/stay_plan/img_optional07.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/anniversary/stay_plan/img_optional07__sp.png',
                    alt: '',
                  },
                  title: <>ベッドフラワー</>,
                  content: (
                    <>
                      造花の花びらでベッドの上にハートを作ります。メッセージカードのご依頼も承ります。
                    </>
                  ),
                  definition: [
                    {
                      title: '料金',
                      text: <>8,800円～</>,
                    },
                  ],
                  footer: (
                    <>
                      <ul className="c_noteList">
                        <li>要予約（4日前まで）</li>
                        <li>料金には消費税が含まれます。</li>
                        <li>
                          お部屋の装飾は、チェックイン前とさせていただきます。
                        </li>
                        <li>
                          花びらはレンタル品のためお持ち帰りいただけません。
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/stay_plan/img_optional08.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/anniversary/stay_plan/img_optional08__sp.png',
                    alt: '',
                  },
                  title: <>ローズバス</>,
                  content: (
                    <>
                      バラの花びらをご用意いたします。バスに浮かべてお楽しみください。
                    </>
                  ),
                  definition: [
                    {
                      title: '料金',
                      text: <>11,000円</>,
                    },
                  ],
                  footer: (
                    <>
                      <ul className="c_noteList">
                        <li>要予約（4日前まで）</li>
                        <li>料金には消費税が含まれます。</li>
                      </ul>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/stay_plan/img_optional09.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/anniversary/stay_plan/img_optional09__sp.png',
                    alt: '',
                  },
                  title: <>フラワーアレンジメント</>,
                  content: <>ガラスの花器に生花をデコレーションいたします。</>,
                  definition: [
                    {
                      title: '料金',
                      text: <>11,000円～</>,
                    },
                  ],
                  footer: (
                    <>
                      <ul className="c_noteList">
                        <li>要予約（4日前まで）</li>
                        <li>
                          花器はレンタル品のためお持ち帰りいただけません。
                        </li>
                        <li>料金には消費税が含まれます。</li>
                      </ul>
                    </>
                  ),
                },
              ]}
            />
            <CBtnList
              data={[
                {
                  label: 'お祝い・記念日トップ',
                  link: {
                    href: '/anniversary/',
                  },
                },
              ]}
            />
          </section>
          <section className="u_mbMedium">
            <LWrap>
              <ul className="c_noteList">
                <li>画像は全てイメージです。</li>
              </ul>
            </LWrap>
          </section>
        </LWrap>
      </section>
      <LStayPlanContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
